export enum ListingStage {
  Active = 'active',
  Inactive = 'inactive',
  Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

export const LISTING_STAGE_OPTIONS: string[] = [];

const stageKeys = Object.keys(ListingStage);

for (let i = 0; i < stageKeys.length; ++i) {
  if (stageKeys[i] === 'Internal_Unknown') {
      // leave this one out of the UI
      continue;
  }
  LISTING_STAGE_OPTIONS.push(stageKeys[i].toLowerCase());
}

export enum ListingStatus {
  Clean = 'clean',
  Dirty = 'dirty',
  Occupied = 'occupied',
  Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

export enum ListingType {
  Condo = 'condo',
  House = 'house',
  Villa = 'villa',
  Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

