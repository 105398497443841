import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions
} from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import './animationStyles.css';
import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import { Helmet } from 'react-helmet-async';
import { brandConfig } from 'src/config';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode)
    }),
    [themeDirection, themeMode]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <>
      <Helmet>
        <link rel="manifest" href={`/${brandConfig.brandCode.toLocaleLowerCase()}/manifest.json`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`/${brandConfig.brandCode.toLowerCase()}/apple-touch-icon.png`}
        />
        <link rel="icon" href={`/${brandConfig.brandCode.toLowerCase()}/favicon.ico`} />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`/${brandConfig.brandCode.toLowerCase()}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`/${brandConfig.brandCode.toLowerCase()}/favicon-16x16.png`}
        />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <MUIThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </MUIThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
