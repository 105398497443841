
export const QueryMessageProviderSuccessText: Record<string, string | null> = {
    getRTKQCases: null,
    getRTKQListingOrderItems: null,
    getRTKQListings: null,
    getRTKQObjectAttachments: null,
    getRTKQOwnerReport: null,
    getRTKQReservations: null,
    getRTKQSingleListing: null,
    getRTKQSingleReservation: null,
    getRTKQStatements: null,
    getRTKQTasks: null,
};

export const MutationMessageProviderSuccessText: Record<string, string | null> = {
    patchRTKQListingCOI: 'Certificate of insurance is being verified.',
    postRTKQCaseComment: null,
    postRTKQReservation: 'Booking successful.',
    putRTKQReservationCancel: 'Booking canceled.',
    putRTKQReservationUpdate: 'Booking updated.'
};

export const QueryMessageProviderErrorText: Record<string, string | null> = {
    getRTKQCases: 'There was a problem getting cases.',
    getRTKQListingOrderItems: 'There was a problem getting work orders.',
    getRTKQListings: 'There was a problem getting properties.',
    getRTKQObjectAttachments: 'There was a problem getting attachments.',
    getRTKQOwnerReport: 'There was a problem getting the report.',
    getRTKQReservations: 'There was a problem getting bookings.',
    getRTKQSingleListing: 'There was a problem getting the property.',
    getRTKQSingleReservation: 'There was a problem getting the booking.',
    getRTKQStatements: 'There was a problem getting statements.',
    getRTKQTasks: 'There was a problem getting tasks.'
};

export const MutationMessageProviderErrorText: Record<string, string | null> = {
    patchRTKQListingCOI: 'Failed to upload pdf.',
    postRTKQCaseComment: 'Unable to comment on case.',
    postRTKQReservation: 'Unable to create booking.',
    putRTKQReservationCancel: 'Unable to cancel booking.',
    putRTKQReservationUpdate: 'Unable to update booking.'
};
