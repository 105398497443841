import { Grid, Stack, Typography } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import Label from '../../../components/label/Label';
import {
  DetailColumn,
  DetailContainer,
  DetailRow
} from '../../../layouts/simple/DetailColumnAndRow';
import ListingOrderItemGeneral, { ListingOrderItemStatus } from '../../../models/mrr/loi';
import { fCurrency } from '../../../utils/formatNumber';
import { FDateFormat, fDate } from '../../../utils/formatTime';

interface Props {
  loi: ListingOrderItemGeneral;
}

export default function LoiDetails({ loi }: Props) {
  const {
    title,
    name_detailed,
    status,
    type,
    sub_type,
    name,
    last_modified_date_ISO,
    end_date_ISO,
    amount,
    discount,
    total_amount,
    tax_amount,
    description
  } = loi;

  return (
    <Scrollbar sx={{ maxHeight: '90dvh' }}>
      <DetailContainer>
        <Grid item>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Typography variant="h5" whiteSpace="nowrap">
              {name}
            </Typography>
            <Label
              width="fit-content"
              variant="soft"
              color={
                (status === ListingOrderItemStatus.approved && 'primary') ||
                (status === ListingOrderItemStatus.close && 'warning') ||
                'info'
              }
            >
              {status}
            </Label>
          </Stack>
        </Grid>
        <DetailRow>
          <DetailColumn fullWidth>
            <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
              Title
            </Typography>
            <Typography variant="body1">{title}</Typography>
          </DetailColumn>
        </DetailRow>
        <DetailRow>
          <DetailColumn fullWidth>
            <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
              Property
            </Typography>
            <Typography variant="body1">{name_detailed}</Typography>
          </DetailColumn>
        </DetailRow>
        <DetailRow>
          <DetailColumn>
            <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
              Type
            </Typography>
            <Typography variant="body1">{type || '-'}</Typography>
          </DetailColumn>
          <DetailColumn>
            <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
              Sub Type
            </Typography>
            <Typography variant="body1">{sub_type || 'Other'}</Typography>
          </DetailColumn>
        </DetailRow>
        {description && (
          <DetailRow>
            <DetailColumn fullWidth>
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Description
              </Typography>
              <Typography variant="body1">{description}</Typography>
            </DetailColumn>
          </DetailRow>
        )}
        <DetailRow>
          {!Number.isNaN(amount) && (
            <DetailColumn third>
              {/* Amount */}
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Amount
              </Typography>
              <Typography variant="body1">{fCurrency(amount, true)}</Typography>
            </DetailColumn>
          )}
          {!Number.isNaN(discount) && (
            <DetailColumn third>
              {/* discount */}
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Discount
              </Typography>
              <Typography variant="body1">{fCurrency(discount, true)}</Typography>
            </DetailColumn>
          )}
          {!Number.isNaN(tax_amount) && (
            <DetailColumn third>
              {/* discount */}
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Taxes
              </Typography>
              <Typography variant="body1">{fCurrency(tax_amount, true)}</Typography>
            </DetailColumn>
          )}
        </DetailRow>
        <DetailRow>
          {!Number.isNaN(total_amount) && (
            <DetailColumn fullWidth={!end_date_ISO}>
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Total Amount
              </Typography>
              <Typography variant="body1">{fCurrency(total_amount, true)}</Typography>
            </DetailColumn>
          )}
          {end_date_ISO && (
            <DetailColumn>
              <Typography variant="overline" sx={{ color: 'text.disabled', pt: 0.5 }}>
                Close Date
              </Typography>
              <Typography variant="body1">
                {fDate(end_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}
              </Typography>
            </DetailColumn>
          )}
        </DetailRow>
        <DetailRow>
          {/* Updated */}
          <DetailColumn fullWidth>
            <Typography variant="body2" sx={{ color: 'text.disabled', pt: 0.5 }}>
              Last updated on {fDate(last_modified_date_ISO, FDateFormat.localized_date_time_full)}
            </Typography>
          </DetailColumn>
        </DetailRow>
      </DetailContainer>
    </Scrollbar>
  );
}
